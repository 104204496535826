import React from 'react';
import styled from 'styled-components';
import { WpGql_Page } from '@/graphql-types';
// Components
import { HeroBanner } from '../Components/Common/HeroBanner';
import { ContentContainer } from '../Components/Common/ContentContainer';
import PostGrid from '../Components/Common/Posts/PostGrid';
import { Buttons } from '../Components/Common/Styles/Interactions';
import { SocialLinks } from '../Components/Footer/SocialLinks';

interface INewsProps {
    pageContext: {
        pageData: WpGql_Page
    }
}

const SinglePostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0px;
  scroll-behavior: smooth;
  @media (min-width: ${props => props.theme.screensizes.medium}) {
    flex-direction: row;
    justify-content:space-between;
  }

  aside {
    margin-bottom: 50px;
    @media (min-width: ${props => props.theme.screensizes.medium}) {
      flex: 0 0 calc(33% - 30px);
      margin-bottom: 0px;
    }
    .related-articles-title {
        font-size: 24px;
        font-weight: 300;
    }
    .content-container {
        padding: 2rem 0 !important;
        [class^="PostGrid__StyledPostGrid"] {
            a {
                flex: 0 0 100%;
            }
            .img-wrapper {
                max-height:190px;
            }
        }
    }
  }
  main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
     > p {
        &:first-child {
            font-size: 20px;
            line-height: 25px;
        }
      }
      img {
          margin: 0px 0px 20px 0px;
      }
    @media (min-width: ${props => props.theme.screensizes.medium}) {
      flex: 0 0 calc(64% - 30px);
    }
  
  }
`;
const ShareWrapper = styled.div`
    border-top: 2px solid var(--casperGrey);
    margin: 50px 0px;
    
    h4 {
        font-weight: bold;
        font-size: 20px;
        font-family: ${props => props.theme.fonts.dinNarrow};
        margin-bottom: 25px;
        text-align: center;
        text-transform: uppercase;
    }
    ul {
        margin: 0 auto 20px auto;
        a{
            padding: 0px;
        }
    }
    a {
        margin: 0 auto;
        width: max-content;
        padding: 18px 40px;
        margin-top: 30px;
    }
`;

const NewsTemplate: React.FC<INewsProps> = ( props ) => {
    const { pageData } = props.pageContext;
    const socialLinks = [
        { url: "#", title: "Facebook" },
        { url: "#", title: "Twitter" },
        { url: "#", title: "LinkedIn" },
    ];
    const { uri, content, heroBannerOptions} = pageData

    const checkHeroBannerOptions = () => {
        const options = Object.keys(heroBannerOptions).length

        let emptyOptions = 0;
        for (const key in heroBannerOptions) {
            if (heroBannerOptions[key] === null || heroBannerOptions[key] === "") emptyOptions += 1
        }

        return ( emptyOptions === options ) ? false : true
    }

    return (
        <>
            { checkHeroBannerOptions() && (
                <HeroBanner data={heroBannerOptions} hasTabs />
            )}
            <ContentContainer>
                <SinglePostWrapper>
                    <main>
                        <section dangerouslySetInnerHTML={{__html: content}}></section>
                        <ShareWrapper>
                            <SocialLinks header="Share" links={socialLinks} />
                            <Buttons.Secondary text="Back to News" href="/knowledge/news" target="_self" />
                        </ShareWrapper>
                    </main>
                    <aside className="recent--posts__sections">
                        <h3 className="related-articles-title">Related Articles</h3>
                        <PostGrid 
                            hasPagination={false} 
                            postsCount={4} 
                            postID={uri}
                        />
                    </aside>
                </SinglePostWrapper>
            </ContentContainer>
        </>
    )
}
export default NewsTemplate;